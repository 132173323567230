import React, { useState, useEffect, useContext } from "react";
import LangContext from "../utils/LangContext";
import { langAPI } from "../api/langAPI";
import ImageSlider from "../utils/ImageSlider";

export default function Kft() {
  const [state, setState] = useState([]);
  const { lang } = useContext(LangContext);

  useEffect(() => {
    langAPI(lang).then(setState);
  }, [lang]);

  return state.map((item, idx) => <RenderKft key={idx} data={item} />);
}

const SliderData = [
  {
    image: require("../images/project_4/kft-oben.png"),
  },
  {
    image: require("../images/project_4/kft-unten.png"),
  },
  {
    image: require("../images/project_4/kft-eva.png"),
  },
];

const RenderKft = ({ data }) => {
  return (
    <>
      <div className="mt-36">
        <h1 className="md:px-40 px-4">{data.body.project_title_2}</h1>
        <p className="md:px-40 px-4">{data.body.kft}</p>
        <ImageSlider slides={SliderData} />
      </div>
    </>
  );
};
