import React from "react";

// import { ThemeProvider } from "@material-tailwind/react";
import ReactDOM from "react-dom/client";
import "./css/style.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { LangProvider } from "./utils/LangContext";
import Layout from "./pages/Layout";
import IndexPage from "./pages/IndexPage";
import Barcamp from "./pages/Barcamp";
import Streifzug from "./pages/Streifzug";
import Kft from "./pages/kft";
import Impressum from "./pages/Impressum";
import Soundlibrary from "./pages/Soundlibrary";

export default function Index() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <React.StrictMode>
      {/* <ThemeProvider> */}
      <BrowserRouter>
        <ScrollToTop />
        <LangProvider>
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <IndexPage />
                </Layout>
              }
            />

            <Route
              path="/project_1"
              element={
                <Layout>
                  <Streifzug />
                </Layout>
              }
            />

            <Route
              path="/project_2"
              element={
                <Layout>
                  <Soundlibrary />
                </Layout>
              }
            />
            <Route
              path="/project_3"
              element={
                <Layout>
                  <Barcamp />
                </Layout>
              }
            />
            <Route
              path="/project_4"
              element={
                <Layout>
                  <Kft />
                </Layout>
              }
            />
            <Route
              path="/impressum"
              element={
                <Layout>
                  <Impressum />
                </Layout>
              }
            />
          </Routes>
        </LangProvider>
      </BrowserRouter>

      {/* </ThemeProvider> */}
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);
